<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Mail Chimps Campaigns
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div>
            <!-- <div class="dataTables_length" id="myTableall_length"><label><h3>All Compaigns</h3></label></div> -->
            <div class="kt-table-content table-responsive">
              <div
                id="productbrandTable_wrapper"
                class="dataTables_wrapper no-footer"
              >
                <div class="dataTables_length" id="productbrandTable_length">
                  <label
                    >Show
                    <select
                      name="productbrandTable_length"
                      class=""
                      v-model="limit"
                      v-on:change="fetchCompaigns()"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                    entries</label
                  >
                </div>
                <!-- <div id="productbrandTable_filter" class="dataTables_filter">
                                    <label>Search:<input type="text" class="" placeholder="" v-model="searchParameter" @keyup="fetchCompaigns()"></label>
                                    <button style="padding: 0.375rem 0.75rem !important;" class="btn btn-light  font-weight-bold mr-1 mb-1"
                                        v-if="this.searchParameter != ''" @click="clearSearch"> Clear </button>
                                </div> -->
                <table id="myTableall" class="table dataTable no-footer">
                  <thead class="kt-table-thead text-body">
                    <tr>
                      <th>List ID</th>
                      <th>Campaign Detail</th>
                      <!-- <th class="kt-table-cell">Emails sent</th> -->
                      <!-- <th class="kt-table-cell">Recipient Count</th> -->
                      <th class="kt-table-cell">Status</th>
                      <th class="kt-table-cell">Clicks</th>
                      <th class="kt-table-cell">Opens</th>
                      <th class="kt-table-cell">Type</th>
                      <th class="kt-table-cell">&nbsp;</th>
                      <th class="kt-table-cell">&nbsp;</th>
                      <!-- <th class="kt-table-cell">Preview Text</th> -->
                      <!-- <th class="kt-table-cell">Subject Line</th> -->
                      <!-- <th class="kt-table-cell">Send Time</th> -->
                      <!-- <th class="kt-table-cell">URL</th> -->
                      <!-- <th class="kt-table-cell">Action</th> -->
                    </tr>
                  </thead>
                  <tbody class="kt-table-tbody text-dark">
                    <tr
                      class="kt-table-row kt-table-row-level-0"
                      v-for="compaign in compaigns"
                      :key="compaign.id"
                    >
                      <td class="kt-table-cell">
                        {{ compaign.recipients.list_id }}
                      </td>
                      <td class="kt-table-cell">
                        <div style="color: rgb(46, 46, 182)">
                          {{ compaign.settings.subject_line }}
                        </div>
                        <div>
                          {{ compaign.recipients.list_name }}
                        </div>
                        <div>
                          <span style="color: rgb(46, 46, 182)">{{
                            compaign.status
                          }}</span
                          >,
                          <span style="font-weight: bold">{{
                            convertTime(compaign.send_time)
                          }}</span>
                          to {{ compaign.emails_sent }} subscribers
                        </div>
                        <div>
                          <a
                            :href="compaign.long_archive_url"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {{ compaign.long_archive_url }}
                          </a>
                        </div>
                      </td>
                      <!-- <td class="kt-table-cell">{{ compaign.emails_sent }}</td> -->
                      <!-- <td class="kt-table-cell">{{ compaign.recipients.recipient_count }}</td> -->
                      <td class="kt-table-cell">
                        <div
                          style="
                            background-color: rgb(73, 207, 73);
                            width: fit-content;
                            padding: 5px;
                            border-radius: 5px;
                            color: #fff;
                          "
                        >
                          {{ compaign.status }}
                        </div>
                      </td>

                      <td class="kt-table-cell">
                        {{ compaign.report_summary.clicks }}
                      </td>
                      <td class="kt-table-cell">
                        {{
                          convertIntoReadable(
                            compaign.report_summary.open_rate
                          )
                        }}%
                      </td>

                      <td class="kt-table-cell">{{ compaign.type }}</td>
                      <!-- <td class="kt-table-cell">{{ compaign.settings.preview_text }}</td> -->

                      <!-- <td class="kt-table-cell">{{ convertTime(compaign.send_time) }}</td> -->
                      <td class="kt-table-cell"></td>

                      <td>
                        <router-link
                          :to="{
                            name: 'mailChimCompaignReport',
                            params: { id: compaign.id },
                          }"
                          class="btn btn-primary"
                        >
                          <span class="nav-text">View Report</span>
                        </router-link>
                        <!-- <button class="" @click="getReport(compaign.id)"></button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li :class="[{ disabled: !pagination.prev_page_url }]">
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchCompaigns(pagination.prev_page_url)"
                    >
                      Previous
                    </button>
                  </li>
                  <li class="disabled">
                    <button
                      class="page-link text-dark"
                      href="javascript:void(0)"
                    >
                      Page {{ pagination.current_page }} of
                      {{ pagination.last_page }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="[{ disabled: !pagination.next_page_url }]"
                  >
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchCompaigns(pagination.next_page_url)"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
import { compareAsc, format } from "date-fns";
import moment from "moment";
export default {
  name: "MailChimpCompaign",

  data() {
    return {
      limit: 10,
      compaigns: [],
      pagination: {},
      convertedTime: "",
    };
  },

  methods: {
    fetchCompaigns(page_url) {
      this.$parent.loading = true;

      page_url = page_url || "/api/admin/mail-chimp-compaign";
      var arr = page_url.split("?");

      if (arr.length > 1) {
        page_url += "&limit=" + this.limit;
      } else {
        page_url += "?limit=" + this.limit;
      }

      page_url += "&getAverage=1";

      axios
        .get(page_url, this.token)
        .then((res) => {
          this.compaigns = res.data.data;
          this.makePagination(res.data.meta, res.data.links);
        })
        .finally(() => (this.$parent.loading = false));
    },

    convertTime(time) {
      // Use moment.js to parse and format the time
      return moment(time).format("YYYY-MM-DD hh:mm A");
    },

    convertIntoReadable(item) {
      return parseFloat(item * 100).toFixed(2);
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: links.next,
        prev_page_url: links.prev,
      };

      this.pagination = pagination;
    },

    dateFormat(date) {
      format(new Date(2014, 1, 11), "yyyy-MM-dd");
    },

    getReport(id) {
      axios
        .get("/api/get-compaign-report/" + id, this.token)
        .then((res) => {
          // this.compaigns = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchCompaigns();
    // this.getReport();
    // this.convertTime();
  },
};
</script>