<template>
  <div class="row">
    <div class="col-xl-9">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header card-t-header d-block border-0 pt-2">
          <div class="card-title mb-0 d-flex justify-content-between">
            <div class="">
              <h3 class="card-label text-body font-weight-bold mb-0">
                Lead Source Stats ( {{ current_month }} )
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body pt-3">
          <div class="text-map-sale">
            <div class="row">
              <div
                class="col-sm-6 col-lg-3"
                v-for="data in leadStats"
                :key="data.id"
              >
                <div class="">
                  <div class="">
                    <h2 class="text-center text-primary">
                      {{ data.total_leads_currenct_month }}
                    </h2>
                    <p class="text-center text-black font-weight-bold">
                      {{ data.lead_source }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center flex-column">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24.883 24.883"
                    >
                      <g id="statistics" transform="translate(0 0)">
                        <path
                          id="Path_334"
                          data-name="Path 334"
                          d="M33.187,11.664a2.176,2.176,0,0,0,1.514-.615l2.158,1.079a2.142,2.142,0,0,0-.027.265,2.187,2.187,0,0,0,4.374,0,2.16,2.16,0,0,0-.219-.937l2.927-2.927a2.16,2.16,0,0,0,.937.219,2.189,2.189,0,0,0,2.187-2.187,2.154,2.154,0,0,0-.109-.648l2.544-1.907A2.186,2.186,0,1,0,48.5,2.187a2.154,2.154,0,0,0,.109.648L46.062,4.742A2.178,2.178,0,0,0,42.883,7.5l-2.927,2.927a2.149,2.149,0,0,0-2.451.4L35.347,9.742a2.142,2.142,0,0,0,.027-.265,2.187,2.187,0,1,0-2.187,2.187Zm0,0"
                          transform="translate(-29.493)"
                          fill="#a9b2ba"
                        ></path>
                        <path
                          id="Path_335"
                          data-name="Path 335"
                          d="M24.154,166.135h-.778V150.729a.729.729,0,0,0-.729-.729H19.731a.729.729,0,0,0-.729.729v15.406H17.544V155.1a.729.729,0,0,0-.729-.729H13.9a.729.729,0,0,0-.729.729v11.032H11.713v-5.2a.729.729,0,0,0-.729-.729H8.068a.729.729,0,0,0-.729.729v5.2H5.881v-8.116a.729.729,0,0,0-.729-.729H2.236a.729.729,0,0,0-.729.729v8.116H.729a.729.729,0,0,0,0,1.458H24.154a.729.729,0,0,0,0-1.458Zm0,0"
                          transform="translate(0 -142.71)"
                          fill="#a9b2ba"
                        ></path>
                      </g>
                    </svg>
                    <p class="text-center font-9">
                      {{ data.lead_source_type }}( {{ data.lead_source_per }}%)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header card-t-header d-block border-0 pt-2">
          <div class="card-title mb-0 d-flex justify-content-between">
            <div class="">
              <h3 class="card-label text-body font-weight-bold mb-0">
                Total Leads Chart
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body pt-3">
          <div class="text-map-sale">
            <LeadStorageChartPie />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header align-items-center border-0">
          <div class="card-title mb-0">
            <h4 class="card-label text-body font-weight-bold mb-0">
              Total Lead Count Based on Source
            </h4>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <div class="kt-table-content table-responsive-sm">
              <table id="myTable" class="table">
                <thead>
                  <tr>
                    <th>Lead Source</th>
                    <th>Total Count</th>
                    <th>Count Per Month</th>
                    <th>Average</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lead in total_leads_source" :key="lead.id">
                    <td>{{ lead.lead_source }}</td>
                    <td>{{ lead.total_results }}</td>
                    <td>
                      <apexchart
                        type="line"
                        height="70"
                        width="200"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </td>
                    <td>{{ lead.total_results }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header align-items-center border-0">
          <div class="card-title mb-0">
            <h4 class="card-label text-body font-weight-bold mb-0">
              Total Lead Count Based on Campaign
            </h4>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <div class="kt-table-content table-responsive-sm">
              <table id="myTable" class="table">
                <thead>
                  <tr>
                    <th>Lead Campaign</th>
                    <th>Total Count</th>
                    <th>Count Per Month</th>
                    <th>Average</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="compaign in total_compaigns_source"
                    :key="compaign.id"
                  >
                    <td>{{ compaign.compaign_id }}</td>
                    <td>{{ compaign.total_results }}</td>
                    <td>
                      <apexchart
                        type="line"
                        height="70"
                        width="200"
                        :options="chartOptionsCompaign"
                        :series="seriesCompaing"
                      ></apexchart>
                    </td>
                    <td>{{ compaign.total_results }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeadStatistics from "./Apaxchart/LeadStatistics.vue";
import LeadStorageChartPie from "./Apaxchart/LeadStorageChartPie.vue";
import LeadListing from "./LeadListing.vue";
export default {
  name: "Insights",
  components: {
    LeadStatistics,
    LeadStorageChartPie,
    LeadListing,
  },

  data() {
    return {
      leadStats: [],
      current_month: "",
      total_leads_source: [],
      compaignStats: [],
      total_compaigns_source: [],

      series: [
        {
          name: "Lead",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },

        stroke: {
          curve: "straight",
        },

        xaxis: {
          labels: {
            show: false,
          },
        },

        yaxis: {
          labels: {
            show: false,
          },
        },

        grid: {
          show: false,
        },
      },

      seriesCompaing: [
        {
          name: "Compaign",
          data: [],
        },
      ],
      chartOptionsCompaign: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },

        stroke: {
          curve: "straight",
        },

        xaxis: {
          labels: {
            show: false,
          },
        },

        yaxis: {
          labels: {
            show: false,
          },
        },

        grid: {
          show: false,
        },
      },
    };
  },

  methods: {
    fetchLeadStatisticPie() {
      axios
        .get("/api/admin/total-leads?getLeadSourceStats=1", this.token)
        .then((res) => {
          this.leadStats = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchLeadSourceAvg() {
      axios
        .get("/api/admin/total-leads?getLeadSourceAvg=1", this.token)
        .then((res) => {
          this.total_leads_source = res.data.data;
          var leadsources = res.data.data;
          var seiresData = [];
          for (var i = 0; i < leadsources.length; i++) {
            const leadSourceData = leadsources[i].lead_source_data;
            for (var j = 0; j < leadSourceData.length; j++) {
              seiresData.push(leadSourceData[j].total_results);
            }
          }
          this.series = [{ name: "Lead Source", data: seiresData }];
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchCompaignSourceAvg() {
      axios
        .get("/api/admin/total-leads?getLeadCompaignAvg=1", this.token)
        .then((res) => {
          this.total_compaigns_source = res.data.data;
          var compaignsources = res.data.data;
          var seiresData = [];
          for (var i = 0; i < compaignsources.length; i++) {
            const compaignSourceData = compaignsources[i].compaign_source_data;
            for (var j = 0; j < compaignSourceData.length; j++) {
              seiresData.push(compaignSourceData[j].total_results);
            }
          }
          this.seriesCompaing = [{ name: "Compaign Source", data: seiresData }];
        })
        .finally(() => (this.$parent.loading = false));
    },

    currentMonth() {
      const date = new Date();
      const month = date.toLocaleString("default", { month: "long" });
      this.current_month = month;
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchLeadStatisticPie();
    this.fetchLeadSourceAvg();
    this.fetchCompaignSourceAvg();
    this.currentMonth();
  },
};
</script>