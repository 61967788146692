import { createStore } from "vuex";

const store = createStore({

    state: {
        //define varaibles
        token : localStorage.getItem('token') || 0,
        name : localStorage.getItem('loginUsername') || 0,
        id : localStorage.getItem('loginUserID') || 0
    },

    mutations: {
        // update variable values
        UPDATE_TOKEN(state, payload) {
            state.token = payload
        },

        UPDATE_NAME(state, payload) {
            state.name = payload
        },

        UPDATE_ID(state, payload) {
            state.id = payload
        }
    },

    actions: {
        // action to be performe and set the value
        setToken(context, payload) {
            localStorage.setItem('token', payload);
            context.commit('UPDATE_TOKEN', payload)
        },

        removeToken(context) {
            localStorage.removeItem('token');
            context.commit('UPDATE_TOKEN', 0);
        },

        setName(context, payload) {
            localStorage.setItem('loginUsername', payload);
            context.commit('UPDATE_NAME', payload)
        },

        removeName(context) {
            localStorage.removeItem('loginUsername');
            context.commit('UPDATE_NAME', 0);
        },

        setID(context, payload) {
            localStorage.setItem('loginUserID', payload);
            context.commit('UPDATE_ID', payload)
        },

        removeID(context) {
            localStorage.removeItem('loginUserID');
            context.commit('UPDATE_ID', 0);
        },
    },

    getters: {
        // get the state variable value

        getToken: function(state) {
            return state.token
        },

        getName: function(state) {
            return state.name
        },

        getID: function(state) {
            return state.id
        }
    }
})

export default store;