<template>
  <div class="se-pre-con" v-if="loading">
    <div class="pre-loader">
      <img class="img-fluid" src="/assets/images/loadergif.gif" alt="loading" />
    </div>
  </div>
  <!--begin::Header Mobile-->
  <MobileHeader />
  <!--end::Header Mobile-->
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <Sidebar />
      <!--begin::Aside-->

      <div class="aside-overlay"></div>
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="tc_wrapper">
        <!--begin::Header-->
        <Header />
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="tc_content">

          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 px-4">
                  <router-view :loading="1"></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--end::Footer-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Main-->

</template>

<script>
import MobileHeader from "./Partials/MobileHeader.vue";
import Sidebar from "./Partials/Sidebar.vue";
import Header from "./Partials/Header.vue";
export default {
    name: "Main",
    components: {
        MobileHeader,
        Sidebar,
        Header,
    },

    data() {
      return {
        loading: false,
      }
    }
}
</script>