<template>
    <div>
        <apexchart
            width="100%"
            height="200%"
            type="line"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script>
export default {
    name: "LeadAnalytics",

    data() {
        return {
            series: [{
                name: 'Leads',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 300,
                    type: 'line',
                },
                stroke: {
                    width: 5,
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    align: 'left',
                    style: {
                        fontSize: "16px",
                        color: '#666'
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        gradientToColors: [ '#FDD835'],
                        shadeIntensity: 1,
                        type: 'horizontal',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    },
                },
                yaxis: {
                    min: 0,
                    max: 160
                }
            },
        }
    },

    methods: {
        fetchLeadGraphData() {
            axios.get("/api/admin/total-leads?getGraphData=1", this.token).then(res => {
                var leads = res.data.data;
                var seiresData = [];
                var categories = [];
                for (var i = 0; i < leads.length; i++) {
                    seiresData.push(leads[i].total_results);
                    categories[i] = leads[i].month;
                }
                this.series = [{ name: "Leads", data: seiresData }];
                this.chartOptions = {
                    ...this.chartOptions,
                    ...{
                        xaxis: {
                            categories,
                        },
                    },
                };
            }).finally(() => (this.$parent.loading = false));
        }
    },

    mounted() {
        var token = this.$store.getters.getToken;
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        this.fetchLeadGraphData();
    }
}
</script>