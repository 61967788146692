<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Campaigns
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
              to="/admin/addcompaign"
              class="btn btn btn-outline-primary"
              type="button"
            >
              Add Campaign <i class="fas fa-plus"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div>
            <!-- <div class="dataTables_length" id="myTableall_length"><label><h3>All Campaigns</h3></label></div> -->
            <div class="kt-table-content table-responsive">
              <div
                id="productbrandTable_wrapper"
                class="dataTables_wrapper no-footer"
              >
                <div class="dataTables_length" id="productbrandTable_length">
                  <label
                    >Show
                    <select
                      name="productbrandTable_length"
                      class=""
                      v-model="limit"
                      v-on:change="fetchCompaigns()"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                    entries</label
                  >
                </div>
                <!-- <div id="productbrandTable_filter" class="dataTables_filter">
                                    <label>Search:<input type="text" class="" placeholder="" v-model="searchParameter" @keyup="fetchCompaigns()"></label>
                                    <button style="padding: 0.375rem 0.75rem !important;" class="btn btn-light  font-weight-bold mr-1 mb-1"
                                        v-if="this.searchParameter != ''" @click="clearSearch"> Clear </button>
                                </div> -->
                <table id="myTableall" class="table dataTable no-footer">
                  <thead class="kt-table-thead text-body">
                    <tr>
                      <th>ID</th>
                      <th>Campaign Name</th>
                      <th class="kt-table-cell">Campaign Cost</th>
                      <th class="kt-table-cell">Campaign Platform</th>
                      <th class="kt-table-cell">Leads Connected</th>
                      <th class="kt-table-cell">Organic</th>
                      <th class="kt-table-cell">Paid</th>
                      <th class="kt-table-cell">Days Remaining</th>
                      <th class="kt-table-cell">Start Date</th>
                      <th class="kt-table-cell">End Date</th>
                      <th class="kt-table-cell">
                        <div class="text-right">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="kt-table-tbody text-dark">
                    <tr
                      class="kt-table-row kt-table-row-level-0"
                      v-for="compaign in compaigns"
                      :key="compaign.id"
                    >
                      <td class="kt-table-cell">{{ compaign.id }}</td>
                      <td class="kt-table-cell">
                        {{ compaign.compaign_name }}
                      </td>
                      <td class="kt-table-cell">
                        {{ compaign.compaign_cost }}
                      </td>
                      <td class="kt-table-cell">
                        {{ compaign.compaign_platform }}
                      </td>
                      <td class="kt-table-cell">{{ compaign.total_leads }}</td>
                      <td class="kt-table-cell">
                        {{ compaign.total_organic_percentage }} %
                      </td>
                      <td class="kt-table-cell">
                        {{ compaign.total_paid_percentage }} %
                      </td>
                      <td class="kt-table-cell">
                        {{ compaign.days_remaining }}
                      </td>
                      <td class="kt-table-cell">{{ compaign.start_date }}</td>
                      <td class="kt-table-cell">{{ compaign.end_date }}</td>
                      <td class="kt-table-cell">
                        <div class="card-toolbar text-end">
                          <button
                            class="btn p-0 shadow-none"
                            type="button"
                            id="dropdowneditButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span class="svg-icon">
                              <i
                                class="fas fa-ellipsis-v text-body"
                                style="width: 20px; height: 20px; padding: 4px"
                              ></i>
                            </span>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdowneditButton"
                            style="
                              position: absolute;
                              transform: translate3d(1001px, 111px, 0px);
                              top: 0px;
                              left: 0px;
                              will-change: transform;
                            "
                          >
                            <router-link
                              :to="{
                                name: 'editcompaign',
                                params: { id: compaign.id },
                              }"
                              class="dropdown-item click-edit"
                              id="click-edit1"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-placement="right"
                              data-original-title="Check out more demos"
                              >Edit</router-link
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li :class="[{ disabled: !pagination.prev_page_url }]">
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchCompaigns(pagination.prev_page_url)"
                    >
                      Previous
                    </button>
                  </li>
                  <li class="disabled">
                    <button
                      class="page-link text-dark"
                      href="javascript:void(0)"
                    >
                      Page {{ pagination.current_page }} of
                      {{ pagination.last_page }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="[{ disabled: !pagination.next_page_url }]"
                  >
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchCompaigns(pagination.next_page_url)"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
import { compareAsc, format } from "date-fns";
export default {
  name: "CompaignListing",

  data() {
    return {
      limit: 10,
      compaigns: [],
      pagination: {},
    };
  },

  methods: {
    fetchCompaigns(page_url) {
      this.$parent.loading = true;

      page_url = page_url || "/api/admin/compaigns";
      var arr = page_url.split("?");

      if (arr.length > 1) {
        page_url += "&limit=" + this.limit;
      } else {
        page_url += "?limit=" + this.limit;
      }

      page_url += "&getAverage=1";

      axios
        .get(page_url, this.token)
        .then((res) => {
          this.compaigns = res.data.data;
          this.makePagination(res.data.meta, res.data.links);
        })
        .finally(() => (this.$parent.loading = false));
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: links.next,
        prev_page_url: links.prev,
      };

      this.pagination = pagination;
    },

    dateFormat(date) {
      format(new Date(2014, 1, 11), "yyyy-MM-dd");
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchCompaigns();
  },
};
</script>