<template>
    <div id="chart">
        <apexchart 
            type="bar" 
            height="350" 
            :options="chartOptions" 
            :series="series">
        </apexchart>
    </div>
</template>

<script>
export default {
    name: "LeadStatistics",

    data() {
        return {
            series: [
                {
                    name: 'Open',
                    data: []
                }, 
                {
                    name: 'Sent',
                    data: []
                },
                {
                    name: 'Joined',
                    data: []
                },
        
            ],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            xaxis: {
              type: 'datetime',
              categories: [],
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          },
        }
    },

    methods: {
        fetchLeadStatistics() {
            axios.get("/api/admin/total-leads?getStatistics=1", this.token).then(res => {
                var leadStatistics = res.data.data;
                leadStatistics.map((leadStatistic) => {

                    this.series[0].data.push(leadStatistic.open_results);
                    this.series[1].data.push(leadStatistic.sent_results);
                    this.series[2].data.push(leadStatistic.joined_results);
                    this.chartOptions.xaxis.categories.push(leadStatistic.year_month);

                })
            }).finally(() => (this.$parent.loading = false));
        }
    },

    mounted() {
        var token = this.$store.getters.getToken;
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        this.fetchLeadStatistics();
    }
}
</script>