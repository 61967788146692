<template>
    <div class="row">
        <div class="col-sm-12">
            <div class=" px-3">
                <div class="card-header align-items-center border-0 d-flex justify-content-between mt-1  mb-4">
                    <div class="card-title mb-0">
                        <h3 class="card-label mb-0 font-weight-bold text-body">
                            Add User
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row ">
        <div class="col-lg-12 col-xl-12">
            <div class="card card-custom gutter-b bg-white border-0">
                <div class="card-body">
                    <div class="row flex-column-reverse flex-xl-row">
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-12 col-sm-4 mb-3">
                                    <label class="form-label">Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name" v-model="user.name">
                                </div>
                                <div class="col-12 col-sm-4 mb-3">
                                    <label class="form-label">Email</label>
                                    <input type="email" class="form-control" placeholder="Enter Email" v-model="user.email">
                                </div>
                                <div class="col-12 col-sm-4 mb-3">
                                    <label class="form-label">Password</label>
                                    <input type="password" class="form-control" placeholder="Enter Password" v-model="user.password">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-4 mb-3">
                                    <label for="" class="form-label">Confirm Password</label>
                                    <input type="password" class="form-control" placeholder="Enter Confirm Password" v-model="user.confirm_password">
                                </div>
                                <div class="col-12 col-sm-4 mb-3">
                                    <label for="" class="form-label">Status</label>
                                    <select class="form-select form-control" aria-label="Default select example" v-model="user.status">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        <option value="disable">Disable</option>
                                    </select>
                                </div>
                            </div>
                            <button class="btn btn btn-primary float-right" type="button" @click="editUser()">
                                Update
                            </button>
                            <router-link to="/admin/users" class="btn btn btn-secondary float-right margin-right-5" type="button" @click="clearForm()">
                                Back
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    <!-- END TABLE -->
    </div>
</template>

<script>
export default {
    name: "UserEdit",

    data() {
        return {
            user: {
                id:"",
                name:"",
                email:"",
                password:"",
                confirm_password:"",
                status:"active",
            }
        }
    },

    methods: {
        fetchUser() {
            axios.get('/api/admin/user/'+this.$route.params.id, this.token).then(res => {
                this.user = res.data.data;
            }).finally(() => (this.$parent.loading = false));
        },

        editUser() {
            axios.put('/api/admin/updateUser/'+this.$route.params.id, this.user, this.token).then(res => {
                if (res.data.status == "Success") {
                    this.$toast.success('User has been updated successfully');
                    this.fetchUser();
                } else {
                    this.$toaster.error(res.data.message);
                }
            }).finally(() => (this.$parent.loading = false));
        },
    },

    mounted() {
        var token = this.$store.getters.getToken;
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        this.fetchUser();
    }
}
</script>

<style scoped>
    .margin-right-5 {
        margin-right: 5px;
    }
</style>