<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">Add Lead</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div class="row flex-column-reverse flex-xl-row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <label for="Plan" class="form-label"
                    >Campaign <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    id="Plan"
                    v-model="lead.compaign_id"
                    required
                  >
                    <option
                      v-for="compaign in compaigns"
                      :key="compaign.id"
                      :value="compaign.id"
                    >
                      {{ compaign.compaign_name }}
                    </option>
                  </select>
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('compaign_id')"
                    v-text="errors.get('compaign_id')"
                  ></small>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter First Name"
                    v-model="lead.first_name"
                    required
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Last Name"
                    v-model="lead.last_name"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                    v-model="lead.email"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">Phone</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Enter Phone"
                    v-model="lead.phone_personal"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="Plan" class="form-label">Package</label>
                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    v-model="lead.package"
                  >
                    <option value="ULTIMATE">ULTIMATE</option>
                    <option value="CORE">CORE</option>
                    <option value="FLEX">FLEX</option>
                  </select>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="Plan" class="form-label"
                    >How did you hear about us?
                  </label>
                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    v-model="lead.hear_about_us"
                  >
                    <option value="Google">Google</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Flyer">Flyer</option>
                    <option value="Friend">Friend</option>
                    <option value="YouTube">YouTube</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">Gym</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Gym Name"
                    v-model="lead.gym"
                  />
                </div>
              </div>
              <button
                class="btn btn btn-primary float-right"
                type="button"
                @click="addLead()"
              >
                Submit
              </button>
              <button
                class="btn btn btn-secondary float-right margin-right-5"
                type="button"
                @click="clearForm()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
import ErrorHandling from "../ErrorHandling";

export default {
  name: "LeadAdd",

  data() {
    return {
      compaigns: [],
      lead: {
        lead_id: "",
        compaign_id: "",
        status: "Open",
        first_name: "",
        last_name: "",
        email: "",
        phone_personal: "",
        package: "Basics Membership",
        gym: "",
        hear_about_us: "",
      },
      errors: new ErrorHandling(),
      error_message: "",
    };
  },

  methods: {
    fetchCompaign() {
      axios
        .get("/api/admin/compaigns", this.token)
        .then((res) => {
          this.compaigns = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },

    addLead() {
      axios
        .post("/api/admin/leadStore", this.lead, this.token)
        .then((res) => {
          if (res.data.status == "Success") {
            this.$toast.success("Lead has been added successfully");
            this.clearForm();
          } else {
            this.$toast.error(res.data.message);
          }
        })
        .catch((error) => {
          this.error_message = "";
          this.errors = new ErrorHandling();
          if (error.response.status == 422) {
            if (error.response.data.status == "Error") {
              this.error_message = error.response.data.message;
            } else {
              this.errors.record(error.response.data.errors);
            }
          }
        })
        .finally(() => (this.$parent.loading = false));
    },

    clearForm() {
      this.lead.lead_id = null;
      this.lead.compaign_id = [];
      this.lead.company = "";
      this.lead.status = "";
      this.lead.first_name = "";
      this.lead.middle_name = "";
      this.lead.last_name = "";
      this.lead.owner = "";
      this.lead.website = "";
      this.lead.title = "";
      this.lead.email = "";
      this.lead.yearly_revenue = "$5k-$10k";
      this.lead.funds_needed = "$5k-$10k";
      this.lead.years_in_business = "";
      this.lead.finance_for = "small_scale_business";
      this.lead.phone_personal = "";
      this.lead.source = "";
      this.lead.time = "week";
      this.lead.credit_score = "excellent";
      this.lead.industry = "";
      this.lead.phone_personal = "";
      this.lead.phone_home = "";
      this.lead.phone_fax = "";
      this.lead.source_company = "";
      this.lead.source_email = "";
      this.lead.source_contact = "";
      this.lead.business_name = "";
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchCompaign();
  },
};
</script>

<style scoped>
.margin-right-5 {
  margin-right: 5px;
}
</style>