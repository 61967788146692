<template>
    <div class="l-form" style="background-image: url(/assets/images/misc/bg-login1.png);">
        <form action="" class="adminloginform" @submit.prevent="forgetPassword">
            <div class="maintitle">
                <div class="card-title mb-0">
                    <h3 class="card-label font-weight-bold mb-0 text-body">
                        <img src="/assets/images/misc/logo.png" alt="logo">
                    </h3>
                    <h5 class="font-size-h5 mb-0 mt-3 text-dark">
                        Enter your email to get reset link
                    </h5>
                </div>   
            </div>
            <div class="form-group  row">
                <div class="col-lg-2 col-3 ">
                    <label for="exampleInputEmail1" class="mb-0 text-dark">
                        <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                            </svg>
                    </label>
                </div>
                    <div class="col-lg-10 col-9 pl-0">
                    <input type="email" name="email" class="form-control bg-transparent text-dark border-0 p-0 h-20px font-size-h5" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email">
            
                </div>
                <span class="help is-danger text-danger p-70" v-if="error_message">
                   {{ error_message }}
               </span>
            </div>
            <div class="form-group row align-items-center justify-content-between">
                   <div class="col-6">
                       <div class="form-check pl-4">
                           <label class="form-check-label text-dark" for="exampleCheck1"></label>
                       </div>
                   </div>
                   <div class="col-6 text-end">
                       <router-link to="/admin/login">Back to Login?</router-link>
                   </div>
               </div>
            <button type="submit" class="btn btn-primary text-white font-weight-bold w-100 py-3 mt-3" data-bs-toggle="modal" data-bs-target="#default">
                Send Link
            </button>
    </form>
    </div>
    
   </template>
   
   <script>
   import router from "../router";
   export default {
       name: "ForgetPassword",
   
       data() {
           return {
               email: "",
               error_message: "",
           }
       },
   
       methods: {
            forgetPassword() {
               localStorage.removeItem('forget_token');
               axios.post('/api/forget_password', {
                   email: this.email,
               }).then( res => {
                   if (res.data.status == 'Success') {
                       localStorage.setItem('forget_token', res.data.data);
                       this.error_message = 'Email Sent Successfully';
                       this.$toast.success('Email Sent Successfully');
                       router.push({name:'login'});
                   } else {
                        this.$toast.error('The given data was invalid.');
                   }
               }).catch(error => {
                    if (error.response.status == 422) {
                        this.error_message = error.response.data.message;
                        this.$toast.error(error.response.data.message);
                    }
                })
           }
       }
   }
   </script>
   
   <style scoped>
   .p-70{
       padding-left: 70px;
   }
   </style>