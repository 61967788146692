<template>
    <div>
        <apexchart 
            type="pie" 
            width="100%" 
            height="200%"
            :options="chartOptions" 
            :series="series">
        </apexchart>
    </div>
</template>

<script>
export default {
    name: "LeadsByCompaign",
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },

    methods: {
        fetchLeadByCompaign() {
            axios.get("/api/admin/total-leads?getLeadByCompaign=1", this.token).then(res => {
                var leadWithCompaigns = res.data.data;
                leadWithCompaigns.map((leadWithCompaign) => {
                    this.series.push(leadWithCompaign.total_results);
                    this.chartOptions.labels.push(leadWithCompaign.compaign_name);
                })
            }).finally(() => (this.$parent.loading = false));
        }
    },

    mounted() {
        var token = this.$store.getters.getToken;
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        this.fetchLeadByCompaign();
    }
}
</script>