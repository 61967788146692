<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Trash Leads
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div>
            <div class="kt-table-content table-responsive">
              <div
                id="productbrandTable_wrapper"
                class="dataTables_wrapper no-footer"
              >
                <div class="dataTables_length" id="productbrandTable_length">
                  <label
                    >Show
                    <select
                      name="productbrandTable_length"
                      class=""
                      v-model="limit"
                      v-on:change="fetchLeads()"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                    entries</label
                  >
                </div>
                <div id="productbrandTable_filter" class="dataTables_filter">
                  <label
                    >Search:<input
                      type="text"
                      class=""
                      placeholder=""
                      v-model="searchParameter"
                      @keyup="fetchLeads()"
                  /></label>
                  <button
                    style="padding: 0.375rem 0.75rem !important"
                    class="btn btn-light font-weight-bold mr-1 mb-1"
                    v-if="this.searchParameter != ''"
                    @click="clearSearch"
                  >
                    Clear
                  </button>
                </div>
                <table id="myTableall" class="table dataTable no-footer">
                  <thead class="kt-table-thead text-body">
                    <tr>
                      <th
                        class="sorting"
                        @click="sorting('id')"
                        :class="
                          (this.$data.sortType == 'asc' ||
                            this.$data.sortType == 'ASC') &&
                          this.$data.sortBy == 'id'
                            ? 'sorting_asc'
                            : (this.$data.sortType == 'desc' ||
                                this.$data.sortType == 'DESC') &&
                              this.$data.sortBy == 'id'
                            ? 'sorting_desc'
                            : 'sorting'
                        "
                      >
                        ID
                      </th>
                      <th
                        class="kt-table-cell"
                        @click="sorting('compaign_id')"
                        :class="
                          (this.$data.sortType == 'asc' ||
                            this.$data.sortType == 'ASC') &&
                          this.$data.sortBy == 'id'
                            ? 'sorting_asc'
                            : (this.$data.sortType == 'desc' ||
                                this.$data.sortType == 'DESC') &&
                              this.$data.sortBy == 'id'
                            ? 'sorting_desc'
                            : 'sorting'
                        "
                      >
                        Campaign
                      </th>
                      <th class="kt-table-cell">Business Name</th>
                      <th
                        class="kt-table-cell"
                        @click="sorting('first_name')"
                        :class="
                          (this.$data.sortType == 'asc' ||
                            this.$data.sortType == 'ASC') &&
                          this.$data.sortBy == 'id'
                            ? 'sorting_asc'
                            : (this.$data.sortType == 'desc' ||
                                this.$data.sortType == 'DESC') &&
                              this.$data.sortBy == 'id'
                            ? 'sorting_desc'
                            : 'sorting'
                        "
                      >
                        Name
                      </th>
                      <th
                        class="kt-table-cell"
                        @click="sorting('email')"
                        :class="
                          (this.$data.sortType == 'asc' ||
                            this.$data.sortType == 'ASC') &&
                          this.$data.sortBy == 'id'
                            ? 'sorting_asc'
                            : (this.$data.sortType == 'desc' ||
                                this.$data.sortType == 'DESC') &&
                              this.$data.sortBy == 'id'
                            ? 'sorting_desc'
                            : 'sorting'
                        "
                      >
                        Email
                      </th>
                      <th class="kt-table-cell">Phone Business</th>
                      <th class="kt-table-cell">Funds Needed</th>
                      <th class="kt-table-cell">Date</th>
                      <th class="kt-table-cell">Status</th>
                      <th class="kt-table-cell">
                        <div class="text-right">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="kt-table-tbody text-dark">
                    <tr
                      class="kt-table-row kt-table-row-level-0"
                      v-for="lead in leads"
                      :key="lead.id"
                    >
                      <td class="kt-table-cell">{{ lead.lead_id }}</td>
                      <td class="kt-table-cell">
                        {{ lead.compaign.compaign_name }}
                      </td>
                      <td class="kt-table-cell">{{ lead.business_name }}</td>
                      <td class="kt-table-cell">
                        {{ lead.first_name }} {{ lead.last_name }}
                      </td>
                      <td class="kt-table-cell">{{ lead.email }}</td>
                      <td class="kt-table-cell">{{ lead.phone_business }}</td>
                      <td class="kt-table-cell">{{ lead.funds_needed }}</td>
                      <td class="kt-table-cell">{{ lead.date }}</td>
                      <td class="kt-table-cell">{{ lead.status }}</td>
                      <td class="kt-table-cell">
                        <div class="card-toolbar text-end">
                          <button
                            class="btn p-0 shadow-none"
                            type="button"
                            id="dropdowneditButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span class="svg-icon">
                              <i
                                class="fas fa-ellipsis-v text-body"
                                style="width: 20px; height: 20px; padding: 4px"
                              ></i>
                            </span>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdowneditButton"
                            style="
                              position: absolute;
                              transform: translate3d(1001px, 111px, 0px);
                              top: 0px;
                              left: 0px;
                              will-change: transform;
                            "
                          >
                            <a
                              class="dropdown-item confirm-delete"
                              title="Delete"
                              href="javascript:void(0)"
                              @click="restoreLead(lead.lead_id)"
                              >Restore</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li :class="[{ disabled: !pagination.prev_page_url }]">
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchLeads(pagination.prev_page_url)"
                    >
                      Previous
                    </button>
                  </li>
                  <li class="disabled">
                    <button
                      class="page-link text-dark"
                      href="javascript:void(0)"
                    >
                      Page {{ pagination.current_page }} of
                      {{ pagination.last_page }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="[{ disabled: !pagination.next_page_url }]"
                  >
                    <button
                      class="page-link"
                      href="javascript:void(0)"
                      @click="fetchLeads(pagination.next_page_url)"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
export default {
  name: "LeadListing",

  data() {
    return {
      limit: 10,
      leads: [],
      pagination: {},
      searchParameter: "",
      sortBy: "id",
      sortType: "ASC",
    };
  },

  methods: {
    fetchLeads(page_url) {
      this.$parent.loading = true;

      page_url = page_url || "/api/admin/onlyTrashed";
      var arr = page_url.split("?");

      if (arr.length > 1) {
        page_url += "&limit=" + this.limit;
      } else {
        page_url += "?limit=" + this.limit;
      }
      if (this.searchParameter != null) {
        page_url += "&searchParameter=" + this.searchParameter;
      }

      page_url +=
        "&sortBy=" +
        this.sortBy +
        "&sortType=" +
        this.sortType +
        "&getDetail=1";

      axios
        .get(page_url, this.token)
        .then((res) => {
          this.leads = res.data.data;
          this.makePagination(res.data.meta, res.data.links);
        })
        .finally(() => (this.$parent.loading = false));
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: links.next,
        prev_page_url: links.prev,
      };

      this.pagination = pagination;
    },

    sorting(sortBy) {
      this.sortBy = sortBy;
      this.sortType =
        this.sortType == "asc" || this.sortType == "ASC"
          ? (this.sortType = "desc")
          : (this.sortType = "asc");
      this.fetchLeads();
    },

    clearSearch() {
      this.searchParameter = "";
      this.fetchLeads();
    },

    restoreLead(id) {
      if (confirm("Are you sure")) {
        this.$parent.loading = true;
        axios
          .get(`/api/admin/restoreLead/${id}`, this.token)
          .then((res) => {
            if (res.data.status == "Success") {
              this.$toast.success("Lead has been restore successfully");
              this.fetchLeads();
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              if (error.response.data.status == "Error") {
                this.$toast.error(error.response.data.message);
              }
            }
          })
          .finally(() => (this.$parent.loading = false));
      }
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchLeads();
  },
};
</script>