<template>

    <router-view> </router-view>

	<!-- <Main /> -->
	
</template>

<script>
// import Main from "./components/Main.vue";
export default {
	name: 'App',
	// components: {
	// 	Main
	// }
}
</script>