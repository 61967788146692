<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Add Campaign
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div class="row flex-column-reverse flex-xl-row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label"
                    >Campaign Platform <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_platform"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('compaign_platform')"
                    v-text="errors.get('compaign_platform')"
                  ></small>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label"
                    >Campaign Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_name"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('compaign_name')"
                    v-text="errors.get('compaign_name')"
                  ></small>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label"
                    >Campaign Cost <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_cost"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('compaign_cost')"
                    v-text="errors.get('compaign_cost')"
                  ></small>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label"
                    >Start Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Enter Source Contact"
                    v-model="compaign.start_date"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('start_date')"
                    v-text="errors.get('start_date')"
                  ></small>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label"
                    >End Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Enter Business Name"
                    v-model="compaign.end_date"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="errors.has('end_date')"
                    v-text="errors.get('end_date')"
                  ></small>
                </div>
              </div>
              <button
                class="btn btn btn-primary float-right"
                type="button"
                @click="addCompaign()"
              >
                Submit
              </button>
              <button
                class="btn btn btn-secondary float-right margin-right-5"
                type="button"
                @click="clearForm()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
import ErrorHandling from "../ErrorHandling";

export default {
  name: "CompaignAdd",

  data() {
    return {
      compaign: {
        id: "",
        compaign_platform: "",
        compaign_name: "",
        compaign_cost: "",
        start_date: "",
        end_date: "",
      },
      errors: new ErrorHandling(),
      error_message: "",
    };
  },

  methods: {
    addCompaign() {
      axios
        .post("/api/admin/compaigns", this.compaign, this.token)
        .then((res) => {
          if (res.data.status == "Success") {
            this.$toast.success("Compaign has been added successfully");
            this.clearForm();
          } else {
            this.$toast.error(res.data.message);
          }
        })
        .catch((error) => {
          this.error_message = "";
          this.errors = new ErrorHandling();
          if (error.response.status == 422) {
            if (error.response.data.status == "Error") {
              this.error_message = error.response.data.message;
            } else {
              this.errors.record(error.response.data.errors);
            }
          }
        })
        .finally(() => (this.$parent.loading = false));
    },

    clearForm() {
      this.compaign.id = null;
      this.compaign.compaign_platform = "";
      this.compaign.compaign_name = "";
      this.compaign.compaign_cost = "";
      this.compaign.start_date = "";
      this.compaign.end_date = "";
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  },
};
</script>

<style scoped>
.margin-right-5 {
  margin-right: 5px;
}
</style>