<template>
    <div>
        <apexchart 
            type="pie" 
            width="100%" 
            height="200%"
            :options="chartOptions" 
            :series="series">
        </apexchart>
    </div>
</template>

<script>
export default {
    name: "LeadWithSource",
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                // fill: {
                //     colors: ['#007BFF', '#00E396', '#FEB019', '#FF4560']
                // }
            },
        }
    },

    methods: {
        fetchLeadStatisticPie() {
            axios.get("/api/admin/total-leads?getStatisticsGraph=1", this.token).then(res => {
                var leadStatisticPies = res.data.data;
                leadStatisticPies.map((leadStatisticPie) => {
                    this.series.push(leadStatisticPie.total_results);
                    this.chartOptions.labels.push(leadStatisticPie.status);
                })
            }).finally(() => (this.$parent.loading = false));
        }
    },

    mounted() {
        var token = this.$store.getters.getToken;
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        this.fetchLeadStatisticPie();
    }
}
</script>