require('./bootstrap');

import { createApp } from 'vue';
import Toaster from "@meforma/vue-toaster";
import router from "./router";
import App from './App.vue';
import store from './store/index.js';
import VueApexCharts from "vue3-apexcharts";

window.axios = require('axios');

const app = createApp(App)

app.use(router)
app.use(store)
app.use(Toaster, {position: 'top-right'})
app.use(VueApexCharts)
app.mount("#app");
