<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Add Campaign
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-body">
          <div class="row flex-column-reverse flex-xl-row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label">Campaign Platform</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_platform"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label">Campaign Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_name"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label class="form-label">Campaign Cost</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company"
                    v-model="compaign.compaign_cost"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">Start Date</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Enter Source Contact"
                    v-model="compaign.start_date"
                  />
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <label for="" class="form-label">End Date</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Enter Business Name"
                    v-model="compaign.end_date"
                  />
                </div>
              </div>
              <button
                class="btn btn btn-primary float-right"
                type="button"
                @click="updateCompaign()"
              >
                Submit
              </button>
              <router-link
                to="/admin/compaigns"
                class="btn btn btn-secondary float-right margin-right-5"
                type="button"
              >
                Back
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TABLE -->
  </div>
</template>

<script>
export default {
  name: "CompaignAdd",

  data() {
    return {
      compaign: {
        id: "",
        compaign_platform: "",
        compaign_name: "",
        compaign_cost: "",
        start_date: "",
        end_date: "",
      },
    };
  },

  methods: {
    fetchCompaign() {
      axios
        .get("/api/admin/compaigns/" + this.$route.params.id, this.token)
        .then((res) => {
          this.compaign = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },

    updateCompaign() {
      axios
        .put(
          "/api/admin/compaigns/" + this.$route.params.id,
          this.compaign,
          this.token
        )
        .then((res) => {
          if (res.data.status == "Success") {
            this.$toast.success("Compaign has been updated successfully");
            this.fetchCompaign();
          } else {
            this.$toaster.error(res.data.message);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchCompaign();
  },
};
</script>

<style scoped>
.margin-right-5 {
  margin-right: 5px;
}
</style>