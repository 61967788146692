<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="px-3">
        <div
          class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
        >
          <div class="card-title mb-0">
            <h3 class="card-label mb-0 font-weight-bold text-body">
              Mail Chimp Report
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="px-3">
          <div
            class="card-header align-items-center border-0 d-flex justify-content-between mt-1 mb-4"
          >
            <div class="card-title mb-0">
              <h3 class="card-label mb-0 font-weight-bold text-body">
                {{ report.emails_sent }} Recipients
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-4">
        <!-- BEGAIN TABLE -->
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card card-custom gutter-b bg-white border-0">
              <div class="card-body p-3">
                <div class="table-responsive">
                  <div class="d-block d-md-flex justify-content-between">
                    <div class="text-body">
                      <p style="font-size: 16px">
                        <strong>Campaign ID:</strong> {{ report.id }}
                      </p>
                    </div>
                    <div class="themes-heading d-flex text-body">
                      <p class="" style="font-size: 16px">
                        <strong>Delivered:</strong>
                        {{ convertTime(report.send_time) }}
                      </p>
                    </div>
                  </div>
                  <div class="d-block d-md-flex justify-content-between">
                    <div class="text-body">
                      <p style="font-size: 16px">
                        <strong>Audience:</strong> {{ audience_name }}
                      </p>
                    </div>
                    <div class="text-body">
                      <p style="font-size: 16px">
                        <strong>URL: </strong>
                        <a
                          :href="report.long_archive_url"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {{ report.long_archive_url }}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="d-block d-md-flex justify-content-between">
                    <div class="text-body">
                      <p style="font-size: 16px">
                        <strong>Subject:</strong> {{ preview_text }}
                      </p>
                    </div>
                    <div class="text-body">
                      <p style="font-size: 16px">
                        <strong>Type:</strong> {{ report.type }}
                      </p>
                    </div>

                    <!-- <div class="themes-heading d-flex text-body">
                                                                <ul class="d-flex text-info " style="font-size: 16px;">
                                                                    <li class="mx-3 "><a class="text-info" href="#">View email</a></li>
                                                                    <li class="mx-3 "><a class="text-info" href="#">Download</a></li>
                                                                    <li class="mx-3 "><a class="text-info" href="#">Print</a></li>
                                                                    <li class="mx-3 "><a class="text-info" href="#">Share</a></li>
                                                                </ul>

                                                            </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END TABLE -->
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card card-custom gutter-b bg-white border-0 p-5">
              <div class="row border rounded">
                <div class="col-md-6 col-lg-3 p-0">
                  <div
                    class="p-5 d-flex justify-content-center align-items-center border border-lg-end"
                  >
                    <div class="text-center">
                      <h3 class="text-info">{{ open }}</h3>
                      <h4>Opened</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 p-0">
                  <div
                    class="p-5 d-flex justify-content-center align-items-center border border-lg-end"
                  >
                    <div class="text-center">
                      <h3>{{ click }}</h3>
                      <h4>Clicked</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 p-0">
                  <div
                    class="p-5 d-flex justify-content-center align-items-center border border-lg-end"
                  >
                    <div class="text-center">
                      <h3>{{ unique_opens }}</h3>
                      <h4>Unique Open</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 p-0">
                  <div
                    class="p-5 d-flex justify-content-center align-items-center"
                  >
                    <div class="text-center">
                      <h3>{{ subscriber_clicks }}</h3>
                      <h4>Subscriber clicks</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-md-6">
                  <div class="card card-custom gutter-b bg-white border-0">
                    <div class="card-body p-3">
                      <div class="table-responsive">
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Successful Deliveries</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ report.emails_sent }} 100.0%
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Total opened</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="text-info" style="font-size: 16px">
                              {{ open }}
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Last opened</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ convertTime(report.send_time) }}
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Subscriber clicks</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ subscriber_clicks }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-custom gutter-b bg-white border-0">
                    <div class="card-body p-3">
                      <div class="table-responsive">
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Successful Deliveries</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ report.emails_sent }} 100.0%
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Total opened</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="text-info" style="font-size: 16px">
                              {{ open }}
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Last opened</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ convertTime(report.send_time) }}
                            </p>
                          </div>
                        </div>
                        <div class="d-block d-md-flex justify-content-between">
                          <div class="text-body">
                            <p style="font-size: 16px">Subscriber clicks</p>
                          </div>
                          <div class="themes-heading d-flex text-body">
                            <p class="" style="font-size: 16px">
                              {{ subscriber_clicks }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END TABLE -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "MailChimCompaignReport",

  data() {
    return {
      report: {},
      convertedTime: "",
      audience_name: "",
      preview_text: "",
      open: "",
      click: "",
      subscriber_clicks: "",
      unique_opens: "",
    };
  },

  methods: {
    fetchCompaigns() {
      axios
        .get("/api/get-compaign-report/" + this.$route.params.id, this.token)
        .then((res) => {
          this.report = res.data.data;
          this.audience_name = this.report.recipients.list_name;
          this.preview_text = this.report.settings.preview_text;
          this.open = this.report.report_summary.opens;
          this.click = this.report.report_summary.clicks;
          this.subscriber_clicks = this.report.report_summary.subscriber_clicks;
          this.unique_opens = this.report.report_summary.unique_opens;
        })
        .finally(() => (this.$parent.loading = false));
    },

    convertTime(time) {
      // Use moment.js to parse and format the time
      return moment(time).format("YYYY-MM-DD hh:mm A");
    },

    convertIntoReadable(item) {
      return item * 100;
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.fetchCompaigns();
    // this.getReport();
    // this.getReport();
    // this.convertTime();
  },
};
</script>