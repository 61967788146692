import {  createWebHistory, createRouter } from "vue-router";
import store  from './store/index.js';
import Main from "./components/Main.vue";
import Dashboard from "./components/Dashboard.vue";
import LeadListing from "./components/LeadListing.vue";
import LeadAdd from "./components/LeadAdd.vue";
import LeadEdit from "./components/LeadEdit.vue";
import LeadTrash from "./components/LeadTrash.vue";
import Statistics from "./components/Statistics.vue";
import Insights from "./components/Insights.vue";
import CompaignListing from "./components/CompaignListing.vue";
import CompaignAdd from "./components/CompaignAdd.vue";
import CompaignEdit from "./components/CompaignEdit.vue";
import UserListings from "./components/UserListings.vue";
import UserAdd from "./components/UserAdd.vue";
import UserEdit from "./components/UserEdit.vue";
import Activity from "./components/Activity.vue";
import Profile from "./components/Profile.vue";
import Login from "./components/Login.vue";
import ForgetPassword from "./components/ForgetPassword.vue";
import ResetPassword from "./components/ResetPassword.vue";
import MailChimpCompaign from "./components/MailChimpCompaign.vue";
import MailChimCompaignReport from "./components/MailChimCompaignReport.vue";

const routes = [
    {
        path: '/admin/', name: 'main', component: Main,
        children: [
            {
                path : '/admin/dashboard',
                name : 'dashboard',
                component : Dashboard,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/leads',
                name : 'lead',
                component : LeadListing,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/addlead',
                name : 'addlead',
                component : LeadAdd,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/lead/:id/edit',
                name : 'editlead',
                component : LeadEdit,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/leadtrash',
                name : 'leadtrash',
                component : LeadTrash,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/statistics',
                name : 'statistics',
                component : Statistics,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/insights',
                name : 'insights',
                component : Insights,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/compaigns',
                name : 'compaign',
                component : CompaignListing,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/mailchimpscompaign',
                name : 'mailchimpscompaign',
                component : MailChimpCompaign,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/mailChimCompaignReport/:id',
                name : 'mailChimCompaignReport',
                component : MailChimCompaignReport,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/addcompaign',
                name : 'addcompaign',
                component : CompaignAdd,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/compaign/:id/edit',
                name : 'editcompaign',
                component : CompaignEdit,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/users',
                name : 'user',
                component : UserListings,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/adduser',
                name : 'adduser',
                component : UserAdd,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/user/:id/edit',
                name : 'edituser',
                component : UserEdit,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/activity',
                name : 'activity',
                component : Activity,
                meta : {
                    requireAuth:true
                }
            },
            {
                path : '/admin/profile',
                name : 'profile',
                component : Profile,
                meta : {
                    requireAuth:true
                }
            }
        ]
    },
    {
        path : '/',
        name : 'home',
        meta:{
            requireAuth:true
        }
    },
    {
        path : '/admin/login',
        name : 'login',
        component : Login,
        meta: {
            requireAuth:false
        }
    },
    {
        path : '/admin/forget',
        name : 'forget',
        component : ForgetPassword,
        meta: {
            requireAuth:false
        }
    },
    {
        path : '/admin/reset-password',
        name : 'resetPassword',
        component : ResetPassword,
        meta: {
            requireAuth:false
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from) => {

    var token = store.getters.getToken;

    if (to.meta.requireAuth && token == 0) {
        return { name : 'login' }
    }
    if (to.meta.requireAuth == false && token != 0) {
        return { name : 'dashboard' }
    }
    if (to.name == 'home') {
        return { name : 'login' }
    }
})

export default router;