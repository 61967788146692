<template>
  <!-- BEGAIN :: sale :: two maps -->
  <div class="row">
    <div class="col-lg col-xl">
      <div
        class="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-open"
      >
        <div class="card-body">
          <h3 class="text-body text-center font-weight-bold m-0">Open</h3>
          <div class="mt-4">
            <div class="d-flex align-items-center justify-content-center">
              <span class="text-dark font-weight-bold font-size-h1 mr-3">
                <span class="counter" data-target="600">{{
                  open.total_leads
                }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg col-xl">
      <div
        class="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-pending"
      >
        <div class="card-body">
          <h3 class="text-body text-center font-weight-bold m-0">Sent</h3>
          <h5 class="text-center">(to call team)</h5>
          <div class="mt-4">
            <div class="d-flex align-items-center justify-content-center">
              <span class="text-dark font-weight-bold font-size-h1 mr-3">
                <span class="counter" data-target="600">{{
                  sent.total_leads
                }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg col-xl">
      <div
        class="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-approved"
      >
        <div class="card-body">
          <h3 class="text-body text-center font-weight-bold m-0">Joined</h3>
          <div class="mt-4">
            <div class="d-flex align-items-center justify-content-center">
              <span class="text-dark font-weight-bold font-size-h1 mr-3">
                <span class="counter" data-target="600">{{
                  joined.total_leads
                }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg col-xl">
                <div class="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-funded">
                    <div class="card-body">
                        <h3 class="text-body text-center font-weight-bold m-0">Declined</h3>
                        <div class="mt-4">
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="text-dark font-weight-bold font-size-h1 mr-3">
                                    <span class="counter" data-target="600">{{ declined.total_leads }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-xl">
                <div class="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-declined">
                    <div class="card-body">
                        <h3 class="text-body text-center font-weight-bold m-0">Funded</h3>
                        <div class="mt-4">
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="text-dark font-weight-bold font-size-h1 mr-3">
                                    <span class="counter" data-target="600">{{ funded.total_leads }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
  </div>

  <div class="row">
    <div class="col-xl-12">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header card-t-header d-block border-0 pt-2">
          <div class="card-title mb-0 d-flex justify-content-between">
            <div class="">
              <h3 class="card-label text-body font-weight-bold mb-0">
                Leads Analytics
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body pt-3">
          <div class="text-map-sale">
            <LeadAnalytics />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END :: sale :: two maps -->

  <!-- BEGAIN :: sale :: two maps -->
  <div class="row">
    <div class="col-xl-6">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header card-t-header d-block border-0 pt-2">
          <div class="card-title mb-0 d-flex justify-content-between">
            <div class="">
              <h3 class="card-label text-body font-weight-bold mb-0">
                Leads By Campaign
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body pt-3">
          <div class="text-map-sale">
            <LeadsByCompaign />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card card-custom gutter-b bg-white border-0">
        <div class="card-header card-t-header d-block border-0 pt-2">
          <div class="card-title mb-0 d-flex justify-content-between">
            <div class="">
              <h3 class="card-label text-body font-weight-bold mb-0">
                Leads Source
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body pt-3">
          <div class="text-map-sale">
            <LeadWithSource />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END :: sale :: two maps -->

  <!-- TABLE BEGAIN :: sale :: two maps -->

  <div class="row flex-reverse-change">
    <div class="col-xl-12">
      <LeadListing styles="parent-style" />
    </div>
  </div>

  <!--TABLE END :: sale :: two maps -->
</template>

<script>
import LeadListing from "./LeadListing.vue";
import LeadAnalytics from "./Apaxchart/LeadAnalytics.vue";
import LeadsByCompaign from "./Apaxchart/LeadsByCompaign.vue";
import LeadWithSource from "./Apaxchart/LeadWithSource.vue";
export default {
  name: "Dashboard",
  components: {
    LeadListing,
    LeadAnalytics,
    LeadsByCompaign,
    LeadWithSource,
  },

  data() {
    return {
      open: "",
      sent: "",
      joined: "",
    };
  },

  methods: {
    getLeadByStatus() {
      axios
        .get("/api/admin/total-leads?getLeadsByStatus=Open", this.token)
        .then((res) => {
          this.open = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
      axios
        .get("/api/admin/total-leads?getLeadsByStatus=Sent", this.token)
        .then((res) => {
          this.sent = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
      axios
        .get("/api/admin/total-leads?getLeadsByStatus=Joined", this.token)
        .then((res) => {
          this.joined = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
  },

  mounted() {
    var token = this.$store.getters.getToken;
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.getLeadByStatus();
  },
};
</script>