<template>
    <div class="row">
        <div class="col-xl-9">
            <div class="card card-custom gutter-b bg-white border-0">
                <div class="card-header card-t-header d-block border-0 pt-2">
                    <div class="card-title mb-0 d-flex justify-content-between">
                        <div class="">
                            <h3 class="card-label text-body font-weight-bold mb-0">
                                Leads Statistics
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-3">
                    <div class="text-map-sale">
                        <LeadStatistics />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="card card-custom gutter-b bg-white border-0">
                <div class="card-header card-t-header d-block border-0 pt-2">
                    <div class="card-title mb-0 d-flex justify-content-between">
                        <div class="">
                            <h3 class="card-label text-body font-weight-bold mb-0">
                                Total Leads Chart
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-3">
                    <div class="text-map-sale">
                        <LeadStatisticPie />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-reverse-change">
        <div class="col-xl-12">
            <LeadListing styles="parent-style" />
        </div>
    </div>
</template>

<script>
import LeadStatistics from './Apaxchart/LeadStatistics.vue';
import LeadStatisticPie from './Apaxchart/LeadStatisticPie.vue';
import LeadListing from "./LeadListing.vue";
export default {
    name: "Statistics",
    components: {
        LeadStatistics,
        LeadStatisticPie,
        LeadListing
    }
}
</script>