<template>
  <div>
    <div
      class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="tc_aside"
    >
      <!--begin::Brand-->
      <div class="brand flex-column-auto" id="tc_brand">
        <!--begin::Logo-->
        <router-link to="/admin/dashboard" class="brand-logo">
          <span class="brand-text">
            <img
              style="height: 39px"
              alt="Logo"
              src="/assets/images/misc/logo.png"
            />
          </span>
        </router-link>
        <!--end::Logo-->
      </div>
      <!--end::Brand-->
      <!--begin::Aside Menu-->
      <div
        class="aside-menu-wrapper flex-column-fluid overflow-auto h-100"
        id="tc_aside_menu_wrapper"
      >
        <!--begin::Menu Container-->
        <div
          id="tc_aside_menu"
          class="aside-menu mb-5"
          data-menu-vertical="1"
          data-menu-scroll="1"
          data-menu-dropdown-timeout="500"
        >
          <!--begin::Menu Nav-->
          <div id="accordion">
            <ul class="nav flex-column">
              <li class="nav-item dashboard-link">
                <router-link to="/admin/dashboard" class="nav-link">
                  <span class="svg-icon nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path
                        d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                      ></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </span>
                  <span class="nav-text"> Dashboard </span>
                </router-link>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="javascript:void(0)"
                  data-bs-target="#media"
                  role="button"
                  aria-expanded="false"
                  aria-controls="media"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-user-circle font-size-h4"></i>
                  </span>
                  <span class="nav-text">Leads</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  class="collapse nav-collapse"
                  id="media"
                  data-bs-parent="#accordion"
                >
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/admin/leads"
                        exact-active-class="exact-active"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="svg-icon nav-icon d-flex justify-content-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">All Leads</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/admin/addlead"
                        exact-active-class="exact-active"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="svg-icon nav-icon d-flex justify-content-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Add Lead</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/admin/leadtrash"
                        exact-active-class="exact-active"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="svg-icon nav-icon d-flex justify-content-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Trash Leads</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/statistics"
                  exact-active-class="exact-active"
                  class="nav-link"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-pager font-size-h4"></i>
                  </span>
                  <span class="nav-text"> Statistics </span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/insights"
                  exact-active-class="exact-active"
                  class="nav-link"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-pager font-size-h4"></i>
                  </span>
                  <span class="nav-text"> Insights </span>
                </router-link>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="javascript:void(0)"
                  data-bs-target="#catalog"
                  role="button"
                  aria-expanded="false"
                  aria-controls="catalog"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-link font-size-h4"></i>
                  </span>
                  <span class="nav-text">Campaigns</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  class="collapse nav-collapse"
                  id="catalog"
                  data-bs-parent="#accordion"
                >
                  <div id="accordion1">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <router-link
                          to="/admin/compaigns"
                          exact-active-class="exact-active"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="svg-icon nav-icon d-flex justify-content-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">All Campaigns</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          to="/admin/addcompaign"
                          exact-active-class="exact-active"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="svg-icon nav-icon d-flex justify-content-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Add Campaign</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#catalogPurchase"
                  role="button"
                  aria-expanded="false"
                  aria-controls="catalogPurchase"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-money-check-alt font-size-h4"></i>
                  </span>
                  <span class="nav-text">Users</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  class="collapse nav-collapse"
                  id="catalogPurchase"
                  data-bs-parent="#accordion"
                >
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/admin/users"
                        exact-active-class="exact-active"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="svg-icon nav-icon d-flex justify-content-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">All Users</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/admin/adduser"
                        exact-active-class="exact-active"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="svg-icon nav-icon d-flex justify-content-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Add User</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/activity"
                  exact-active-class="exact-active"
                  class="nav-link"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-pager font-size-h4"></i>
                  </span>
                  <span class="nav-text"> Activity Log </span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/mailchimpscompaign"
                  exact-active-class="exact-active"
                  class="nav-link"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-pager font-size-h4"></i>
                  </span>
                  <span class="nav-text">MailChimps Stats</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!--end::Menu Nav-->
        </div>
        <!--end::Menu Container-->
      </div>
      <!--end::Aside Menu-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>